import React from "react";

import {
    Row,
    Col,
    Input,
    Card,
    CardHeader,
    CardTitle,
    Alert,
    Table,
    FormGroup,
    Button,
    Pagination,
    PaginationItem,
    PaginationLink,
} from "reactstrap";

import { service } from "services";

import { DropdownList } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import hr from "date-fns/locale/hr";

import CardBody from "reactstrap/lib/CardBody";

import Download from "components/Excel";

registerLocale('hr', hr);

var to;

var lastweek = new Date();
lastweek.setDate(lastweek.getDate()-7);

var default_params = { oib: '', first_name: '', last_name: '', location_id: '', date_from: lastweek, date_to: new Date(), date_total: new Date(), date_total_from: new Date() };

export default class Reports extends React.Component {
    constructor(props) {
        super(props);

        this.getData = this.getData.bind(this);
        this.search = this.search.bind(this);
        this.onAlertDismiss = this.onAlertDismiss.bind(this);
        this.changePage = this.changePage.bind(this);
  
        this.state = {
          display: 'summary',
          params: default_params,
          locations: [],
          reports: {
            totals: {},
            locations: []
          },
          error: '',
          top:0,
          searchdata: [],
          searchlunch: 0,
          searchdinner: 0,
          page: 1,
          rowsPerPage: 10,
          total: 0,
        };
    }

    componentDidMount() {
      var u = 'locations';
      const requestOptions = {
        method: 'GET',
        headers: { ...service.getSignedHeaders('GET', u) },
        body: null,
      };
  
      fetch(process.env.REACT_APP_API + u, requestOptions)
      .then(response => response.json())
      .then(
          data => {
              if (data.errorCode === 100001) {
                  service.logout();
                  window.location.href = '/';
              } else {
                  this.setState({locations: data.locations});
              }
          }
      );

      document.getElementsByClassName("main-panel")[0].addEventListener('scroll', this.listenToScroll);
      this.getData();
    }

    componentWillUnmount(){
      document.getElementsByClassName("main-panel")[0].removeEventListener('scroll', this.listenToScroll);
    }

    listenToScroll = () => {
      var elem = document.getElementsByClassName("main-panel")[0];
      const winScroll = elem.scrollTop;
    
      this.setState({
        top: winScroll,
      })
    }

    componentDidUpdate() {
    }


    onChange(d, m) {
      clearTimeout(to);
      var params = {...this.state.params};
      params[m] = d;

      this.setState({ params: params });

      if (m === 'date_total' || m === 'date_total_from') setTimeout(() => this.getData(), 300);
    }

    getData(d_from, d_to) {
      const { date_total, date_total_from } = this.state.params;

      if (!d_from) d_from = date_total_from;
      if (!d_to) d_to = date_total;

      var d_from_f = d_from.getFullYear() + "-" + service.nulling(d_from.getMonth() + 1) + "-" + service.nulling(d_from.getDate());
      var d_to_f = d_to.getFullYear() + "-" + service.nulling(d_to.getMonth() + 1) + "-" + service.nulling(d_to.getDate());

      var u = 'reports?date_from=' + d_from_f + '&date_to=' + d_to_f;
      const requestOptions = {
        method: 'GET',
        headers: { ...service.getSignedHeaders('GET', u) },
        body: null,
      };
  
      fetch(process.env.REACT_APP_API + u, requestOptions)
        .then(response => response.json())
        .then(
          data => {
            if (data.errorCode === 100001) {
              service.logout();
              window.location.href = '/';
            } else if (data.errorCode > 0) {
              this.setState({ error: data.errorMessage });
              setTimeout(this.onAlertDismiss, 3000);
            } else {
              this.setState({reports: data.reports});
            }
          }
        );
    }

    search(page) {
      const { oib, first_name, last_name, location_id, date_from, date_to } = this.state.params;

      if (date_from !== '' && date_to !== '') {
        if (date_from > date_to) {
          setTimeout(this.onAlertDismiss, 3000);
          this.setState({error: 'Datum od mora biti manji od datuma do!'});
        } else {
          var dt_from = new Date(date_from);
          var dt_f = dt_from.getFullYear() + "-" + service.nulling(dt_from.getMonth() + 1) + "-" + service.nulling(dt_from.getDate());
          var dt_to = new Date(date_to);
          var dt_t = dt_to.getFullYear() + "-" + service.nulling(dt_to.getMonth() + 1) + "-" + service.nulling(dt_to.getDate());

          var u = 'report?oib=' + oib + '&first_name=' + first_name + '&last_name=' + last_name + '&location_id=' + location_id + '&date_from=' + dt_f + '&date_to=' + dt_t + '&page=' + page + '&limit=' + this.state.rowsPerPage;
          const requestOptions = {
            method: 'GET',
            headers: { ...service.getSignedHeaders('GET', u) },
            body: null,
          };
      
          fetch(process.env.REACT_APP_API + u, requestOptions)
            .then(response => response.json())
            .then(
              data => {
                if (data.errorCode === 100001) {
                  service.logout();
                  window.location.href = '/';
                } else if (data.errorCode > 0) {
                  this.setState({ error: data.errorMessage });
                  setTimeout(this.onAlertDismiss, 3000);
                } else {
                  var this_error = '';
                  if (data.report.length === 0) {
                    this_error = 'Nema rezultata!';
                    setTimeout(this.onAlertDismiss, 3000);
                  }

                  this.setState({searchdata: data.report, searchlunch: data.lunch, searchdinner: data.dinner, error: this_error, total: data.total, page: page});
                }
              }
            );
        }
      } else {
        setTimeout(this.onAlertDismiss, 3000);
        this.setState({error: 'Datum od i datum do su obavezni!'});
      }
    }

    onAlertDismiss() {
      this.setState({ error: '' });
    }

    show(s) {
      this.setState({display: s});
    }

    changePage(p) {
      var page = this.state.page + p;

      this.search(page);
    }

    render() {
        const { reports, error, top, params, locations, searchdata, searchlunch, searchdinner, display, page, rowsPerPage, total } = this.state;

        const pages = Math.ceil(total / rowsPerPage);

        const pinfo = <PaginationItem>
          <PaginationLink tag="button" style={{border: 0, color: 'black', margin: '0px 10px'}}>{(page-1) * rowsPerPage} - {page * rowsPerPage > total ? total : page * rowsPerPage} of {total}</PaginationLink>
        </PaginationItem>

        return (
          <>
          { error !== "" &&
          <Alert color="danger" isOpen={true} style={{top: top + 'px'}} toggle={this.onAlertDismiss} >
            {error}
          </Alert>
          }
          {display === 'summary' ? 
          <Card>
            <CardHeader className="cardHeader">
              <CardTitle tag="h5" style={{width: '60%'}}>
                <FormGroup style={{float: 'left', marginRight: '1%', lineHeight: '40px'}}>Ukupno izdanih obroka na dan</FormGroup>
                <FormGroup style={{float: 'left', marginRight: '1%'}}>
                <DatePicker
                  locale="hr"
                  id="date_total_from"
                  autoComplete="new-password"
                  disabledKeyboardNavigation
                  dateFormat="d.M.yyyy"
                  onChange={(d) => this.onChange(d, "date_total_from")}
                  className="form-control inputdata bigger"
                  highlightDates={[new Date()]}
                  showTimeInput={false}
                  wrapperClassName="small-inline"
                  selected={params.date_total_from ? new Date(params.date_total_from) : ''}
                />
                </FormGroup>
                <FormGroup style={{float: 'left', marginRight: '1%', lineHeight: '40px'}}>do </FormGroup>
                <FormGroup style={{float: 'left', marginRight: '1%'}}>
                <DatePicker
                  locale="hr"
                  id="date_total"
                  autoComplete="new-password"
                  disabledKeyboardNavigation
                  dateFormat="d.M.yyyy"
                  onChange={(d) => this.onChange(d, "date_total")}
                  className="form-control inputdata bigger"
                  highlightDates={[new Date()]}
                  showTimeInput={false}
                  wrapperClassName="small-inline"
                  selected={params.date_total ? new Date(params.date_total) : ''}
                />
                </FormGroup>
              </CardTitle>
              <Button
                className="btn-round"
                color="alert"
                type="button"
                onClick={() => this.show('search')}
              >
                Pretraživanje
              </Button>
              <Download reports={reports} date_to={params.date_total} date_from={params.date_total_from} />
            </CardHeader>
            <CardBody>
              {
                reports.totals.lunch_quantity !== undefined &&
                <>
                <Row style={{marginTop: '20px'}}>
                  <Col md="5"></Col>
                  <Col md="2">
                    <div style={{fontSize: '18px', textAlign: 'center'}}> Ukupno </div>
                  </Col>
                </Row>
                <Row>
                    <Col md="5"></Col>
                    <Col md="2">
                      <Input readOnly value={reports.totals.lunch_quantity + reports.totals.dinner_quantity} className="inputdata text-center" />
                    </Col>
                  </Row>
                  </>
                }
                  <Row style={{marginTop: '50px'}}>
                    <Col>
                        <h5>Broj danas izdanih obroka po lokaciji</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Lokacija</th>
                            <th className="text-center">Ukupno izdano</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          reports.locations.map((location, index) => {
                            return (
                              <tr key={index}>
                                <td>{ location.name }</td>
                                <td className="text-center">{location.lunch_quantity + location.dinner_quantity}</td>
                              </tr>
                            )
                          })
                        }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              :
              <Card>
                  <CardHeader className="cardHeader">
                    <CardTitle tag="h5">Pretraživanje</CardTitle>
                    <Button
                      className="btn-round"
                      color="alert"
                      type="button"
                      onClick={() => this.show('summary')}
                    >
                      Dnevno izvješće
                    </Button>
                  </CardHeader>
                  <CardBody>
                  <Row>
                    <Col md="3">
                        <FormGroup style={{width: '48%', float: 'left', marginRight: '2%'}}>
                        <label>Datum od</label>
                        <DatePicker
                                locale="hr"
                                id="date_from"
                                autoComplete="new-password"
                                disabledKeyboardNavigation
                                dateFormat="d.M.yyyy"
                                onChange={(d) => this.onChange(d, "date_from")}
                                className="form-control inputdata"
                                highlightDates={[new Date()]}
                                showTimeInput={false}
                                selected={params.date_from ? new Date(params.date_from) : ''}
                            />
                        </FormGroup>
                        <FormGroup style={{width: '48%', float: 'left'}}>
                        <label>Datum do</label>
                        <DatePicker
                                locale="hr"
                                id="date_to"
                                autoComplete="new-password"
                                disabledKeyboardNavigation
                                dateFormat="d.M.yyyy"
                                onChange={(d) => this.onChange(d, "date_to")}
                                className="form-control inputdata"
                                highlightDates={[new Date()]}
                                showTimeInput={false}
                                selected={params.date_to ? new Date(params.date_to) : ''}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup>
                        <label>OIB</label>
                        <Input
                            id="oib"
                            value={params.oib}
                            onChange={(d) => this.onChange(d.currentTarget.value, "oib")}
                            placeholder=""
                            type="text"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup>
                        <label>Prezime</label>
                        <Input
                            id="last_name"
                            value={params.last_name}
                            onChange={(d) => this.onChange(d.currentTarget.value, "last_name")}
                            placeholder=""
                            type="text"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup>
                        <label>Ime</label>
                        <Input 
                            value={params.first_name}
                            onChange={(d) => this.onChange(d.currentTarget.value, "first_name")}
                            placeholder="" 
                            type="text" 
                            id="first_name"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup>
                        <label>Lokacija</label>
                        <DropdownList
                            id="location_id"
                            data={locations}
                            valueField='id'
                            value={params.location_id}
                            onChange={(d) => this.onChange(d.id, "location_id")}
                            textField='name'
                            className="inputdata"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="1">
                      <Button
                          className="btn-round inline"
                          color="alert"
                          type="button"
                          onClick={() => this.search(1)}
                      >
                          Traži
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                        <h5 className="card-divider-title text-center">Rezultati</h5>
                    </Col>
                  </Row>
                  <Row style={{marginTop: '20px'}}>
                  <Col md="5"></Col>
                    <Col md="2">
                      <div style={{fontSize: '18px', textAlign: 'center'}}>Ukupno</div>
                      <Input value={total} className="inputdata text-center" readOnly />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Datum i vrijeme</th>
                            <th>Korisnik</th>
                            <th>OIB</th>
                            <th>Lokacija</th>
                          </tr>
                        </thead>
                        <tbody style={{maxHeight: '500px', overflow: 'auto'}}>
                        {
                          searchdata.length === 0 ?
                            <tr>
                              <td colSpan={7} className="text-center">Nema rezultata</td>
                            </tr>
                          :
                          searchdata.map((data, index) => {
                              return <tr key={index}>
                                  <td>{data.date + " " + data.time}</td>
                                  <td>{data.name + " " + data.last_name}</td>
                                  <td>{data.oib}</td>
                                  <td>{data.location_name + ", " + data.city_name}</td>
                                </tr>
                            })
                        }
                        </tbody>
                      </Table>
                      <nav style={{float: 'right'}}>
                        <Pagination>
                          <PaginationItem onClick={() => page > 1 ? this.changePage(-1) : null }>
                            <PaginationLink disabled={page > 1 ? '': true}>
                              Prethodna
                            </PaginationLink>
                          </PaginationItem>
                          {pinfo}
                          <PaginationItem onClick={() => page < pages ? this.changePage(1) : null}>
                            <PaginationLink next tag="button" disabled={page < pages ? '': true}>
                              Sljedeća
                            </PaginationLink>
                          </PaginationItem>
                          <PaginationItem></PaginationItem>
                        </Pagination>
                      </nav>
                    </Col>
                  </Row>
              </CardBody>
            </Card>
            }
          </>
        );
    }
};
