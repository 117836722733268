const states = [
   {
      "id":"Sisačko-moslavačka",
      "name":"Sisačko-moslavačka",
   },
   {
      "id": "Grad Zagreb",
      "name": "Grad Zagreb",
   },
   {
      "id": "Bjelovarsko-bilogorska",
      "name": "Bjelovarsko-bilogorska",
   },
   {
      "id": "Brodsko-posavska",
      "name": "Brodsko-posavska",
   },
   {
      "id": "Dubrovačko-neretvanska",
      "name": "Dubrovačko-neretvanska",
   },
   {
      "id": "Istarska",
      "name": "Istarska",
   },
   {
      "id": "Karlovačka",
      "name": "Karlovačka",
   },
   {
      "id": "Koprivničko-križevačka",
      "name": "Koprivničko-križevačka",
   },
   {
      "id": "Krapinsko-zagorska",
      "name": "Krapinsko-zagorska",
   },
   {
      "id": "Ličko-senjska",
      "name": "Ličko-senjska",
   },
   {
      "id": "Međimurska",
      "name": "Međimurska",
   },
   {
      "id": "Osječko-baranjska",
      "name": "Osječko-baranjska",
   },
   {
      "id": "Požeško-slavonska",
      "name": "Požeško-slavonska",
   },
   {
      "id": "Primorsko-goranska",
      "name": "Primorsko-goranska",
   },
   {
      "id": "Sisačko-moslavačka",
      "name": "Sisačko-moslavačka",
   },
   {
      "id": "Splitsko-dalmatinska",
      "name": "Splitsko-dalmatinska",
   },
   {
      "id": "Varaždinska",
      "name": "Varaždinska",
   },
   {
      "id": "Virovitičko-podravska",
      "name": "Virovitičko-podravska",
   },
   {
      "id": "Vukovarsko-srijemska",
      "name": "Vukovarsko-srijemska",
   },
 ]

export {states};