const organizations = [
    {
        "id":"Crveni križ",
        "name":"Crveni križ"
     },
     {
        "id":"Civilna zaštita",
        "name":"Civilna zaštita"
     },
     {
        "id":"Ostalo",
        "name":"Ostalo"
     },
 ];

export {organizations};