/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect } from "react-router-dom";

import Lists from 'components/Lists';

import DemoNavbar from "components/Navbars/DemoNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

import '../assets/css/style.css';

import { service } from '../services';

var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      updateNavbar: false,
      user_info: '',
      user_last_name: '',
      user_name: '',
      user_role_id: 0,
      user_role_name: '',
      user_location: '',
      user_today_location_lunch_quantity: '',
      user_today_location_dinner_quantity: '',
      user_organization: '',
    };

    this.mainPanel = React.createRef();
    this.getUserInfo = this.getUserInfo.bind(this);
    this.onRouteUpdate = this.onRouteUpdate.bind(this);
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1 && this.mainPanel.current) {
      ps = new PerfectScrollbar(this.mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
    this.getUserInfo();
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1 && ps) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
    if (this.props !== e) {
      this.getUserInfo();
    }
  }
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };

  onRouteUpdate() {
    this.getUserInfo();
  }

  getUserInfo() {
    var access_key = localStorage.getItem("access_key");
    var secret_key = localStorage.getItem("secret_key");

    const requestOptions = {
      method: 'GET',
      headers: { ...service.getSignedHeaders('GET', 'userinfo') },
      body: null,
    };

    if (access_key && secret_key) {
      fetch(process.env.REACT_APP_API + 'userinfo', requestOptions)
        .then(response => response.json())
        .then(resp => {
          if (resp.errorCode === 100001) {
            service.logout();
            window.location.href = '/';
          } else {
            if (resp.user) {
              var user_info = resp.user.last_name + ' ' + resp.user.name;
              if (resp.user.role_name !== '') user_info += ', ' + resp.user.role_name;
              if (resp.user.user_organization !== '') user_info += ', ' + resp.user.user_organization;
              
              var user_location = '';
              if (resp.user.location_name !== '') {
                user_location = resp.user.location_name;
                if (resp.user.location_street !== '') user_location += ', '+ resp.user.location_street;
                if (resp.user.city_name !== '') user_location += ', '+ resp.user.city_name;
              } else {
                user_location = 'Bez lokacije';
              }

              this.setState({
                user_last_name: resp.user.last_name,
                user_name: resp.user.name,
                user_role_id: resp.user.role_id,
                user_role_name: resp.user.role_name,
                user_location: user_location,
                user_today_location_lunch_quantity: resp.user.today_location_lunch_quantity,
                user_today_location_dinner_quantity: resp.user.today_location_dinner_quantity,
                user_organization: resp.user.user_organization,
                user_info: user_info,
              });
            }
          }
        });
      }
  }

  render() {
    var f_routes = [];
    var main = '';

    routes.map((route, key) => {
      if (route.roles.includes(this.state.user_role_id)) {
        if (main === '') main = route.path;
        f_routes.push(route);
      }
    });

    return (
      service.checkLogin() ? (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={f_routes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />
        <div className="main-panel" ref={this.mainPanel}>
          <DemoNavbar {...this.props} user_info={this.state.user_info} user_location={this.state.user_location} />
          <Switch>
            {f_routes.map((prop, key) => {
              return (
                <Route
                  path={prop.layout + prop.path}
                  
                  render={() => (
                    <Lists onUpdate={this.onRouteUpdate} role_id={this.state.user_role_id} location={{"pathname": prop.layout + prop.path}} />
                  )}
                  key={key}
                />
              );
            })}
          </Switch>
          <Footer fluid />
        </div>
        { main !== '' && <Redirect to={"/admin" + main} /> }
      </div>
      ):(
        <Redirect to="/login" />
      )
    );
  }
}

export default Dashboard;
