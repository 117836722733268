import React from "react";

import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
} from "reactstrap";
import CardFooter from "reactstrap/lib/CardFooter";

import CitizensForm from "views/Citizens/Form";
import UsersForm from "views/Users/Form";

export default class MainForm extends React.Component {
    constructor(props) {
      super(props);

      const display = this.props.display ? this.props.display : false;
      const module = this.props.module ? this.props.module : '';
      const id = this.props.id ? this.props.id : 0;
      const action = this.props.action ? this.props.action : '';
      const form = this.props.form ? this.props.form : '';
  
      this.state = {
        display: display,
        module: module,
        id: id,
        action: action,
        form: form,
      };
    }

    componentDidUpdate() {
        var s = this.state;
        var p = this.props;

        var changed = false;
        if (p.display !== undefined && p.display !== s.display) changed = 1;
        if (p.module !== undefined && p.module !== s.module) changed = 1;
        if (p.id !== undefined && p.id !== s.id) changed = 1;
        if (p.action !== undefined && p.action !== s.action) changed = 1;
        if (p.form !== undefined && p.form !== s.form) changed = 1;

        if (changed) {
            var display = p.display !== undefined ? p.display : s.display;
            var module = p.module !== undefined ? p.module : s.module;
            var id = p.id !== undefined ? p.id : s.id;
            var action = p.action !== undefined ? p.action : s.action;
            var form = p.form !== undefined ? p.form : s.form;

            this.setState({ display: display, module: module, id: id, action: action, form: form });
        }
    }

    render() {
        const { module, id, action, form } = this.state;

        var F = null;
        var Fbuttons = true;
        var Ftitle = '';
        var cancelTitle = 'Odustani';
        var forceCancel = false;

        switch (module) {
            case 'citizens':
                F = CitizensForm;
                Ftitle = 'Administracija građana';
                if (this.props.role_id !== 2) {
                    Fbuttons = false;
                    cancelTitle = 'Zatvori';
                    forceCancel = true;
                }
                break;
            case 'users':
                F = UsersForm;
                Ftitle = 'Administracija korisnika';
                break;
            default:
                break;
        }

        return (
            <Card className={"formcard card-" + module + form}>
                <CardHeader className={"cardHeader-" + module + form}>
                    <CardTitle tag="h5">{Ftitle}</CardTitle>
                </CardHeader>
                <CardBody>
                    {F !== null && <F id={id} onChange={this.props.onChange} setData={this.props.setData} role_id={this.props.role_id} action={action} form={form} />}
                </CardBody>
                <CardFooter>
                    { Fbuttons && 
                    <Button
                        className="btn-round"
                        color="primary"
                        type="button"
                        onClick={() => this.props.submitHandler()}
                    >
                        Snimi
                    </Button>
                    }
                    <Button
                        className="btn-round"
                        color="alert"
                        type="button"
                        onClick={() => this.props.cancelHandler(forceCancel)}
                    >
                        {cancelTitle}
                    </Button>
                </CardFooter>
          </Card>
          );
    }
};
