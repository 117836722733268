/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactTooltip from "react-tooltip";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  Container,
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

import routes from "routes.js";
import { service } from "services";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      dropdownOpen: false,
      color: "transparent",
      modal: false,
      modalTitle: '',
      modalConfirm: '',
      user_info: props.user_info,
      user_location: props.user_location
    };
    this.toggle = this.toggle.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.cancelHandler = this.cancelHandler.bind(this);
    this.confirmModal = this.confirmModal.bind(this);
    this.sidebarToggle = React.createRef();
  }

  toggle() {
    if (this.state.isOpen) {
      this.setState({
        color: "transparent",
      });
    } else {
      this.setState({
        color: "dark",
      });
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  dropdownToggle(e) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  getBrand() {
    let brandName = "Default Brand";
    routes.map((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        brandName = prop.name;
      }
      return null;
    });
    return brandName;
  }

  openSidebar() {
    document.documentElement.classList.toggle("nav-open");
    this.sidebarToggle.current.classList.toggle("toggled");
  }

  updateColor() {
    if (window.innerWidth < 993 && this.state.isOpen) {
      this.setState({
        color: "dark",
      });
    } else {
      this.setState({
        color: "transparent",
      });
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateColor.bind(this));
  }

  componentDidUpdate(e) {
    if (this.props !== e) {
      this.setState({user_info: this.props.user_info, user_location: this.props.user_location});
    }

    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      this.sidebarToggle.current.classList.toggle("toggled");
    }
  }

  logout() {
    this.setState({ modal:true, modalTitle: 'Izlaz iz aplikacije', modalConfirm: 'Izađi'});
  }

  cancelHandler() {
    this.setState({ modal:false});
  }

  confirmModal() {
    service.logout();
    window.location.href = "/";
  }

  render() {
    const { modal, modalTitle, modalConfirm } = this.state;

    return (
      // add or remove classes depending if we are on full-screen-maps page or not
      <Navbar
        color={
          this.props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "dark"
            : this.state.color
        }
        expand="lg"
        className={
          this.props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "navbar-absolute fixed-top"
            : "navbar-absolute fixed-top " +
              (this.state.color === "transparent" ? "navbar-transparent " : "")
        }
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                ref={this.sidebarToggle}
                className="navbar-toggler"
                onClick={() => this.openSidebar()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="/">{this.getBrand()}</NavbarBrand>
          </div>
            <Nav navbar>
              <NavItem>
                <div>
                  <i className="fa_button fas fa-sign-out-alt text-gray-dark logoutbutton" onClick={() => this.logout()} data-tip data-for="logout_button" />
                  <ReactTooltip id="logout_button" place="top" effect="solid">Izlaz</ReactTooltip>
                  <div className="userinfo">
                    { this.state.user_info }<br />
                    { this.state.user_location }
                  </div>
                </div>
              </NavItem>
            </Nav>
        </Container>
        <Modal isOpen={modal} className="">
            <ModalHeader toggle={this.cancelHandler}>{modalTitle}</ModalHeader>
            <ModalBody>
                Jeste li sigurni?
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={this.cancelHandler}>Odustani</Button>{' '}
                <Button color="primary" onClick={this.confirmModal}>{modalConfirm}</Button>
            </ModalFooter>
        </Modal>
      </Navbar>
    );
  }
}

export default Header;
