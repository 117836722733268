/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  Pagination, PaginationItem, PaginationLink
} from "reactstrap";

import ReactTooltip from "react-tooltip";
import Loader from 'react-loader-spinner';

import { states } from 'variables/states';
import { service } from "services";

let states_array = [];

var default_params = { oib: '', name: '', last_name: '', address: '' };

class Citizens extends React.Component {
  constructor(props) {
    super(props);

    this.getData = this.getData.bind(this);
    this.onChange = this.onChange.bind(this);
    this.changeWidth = this.changeWidth.bind(this);
    this.changePage = this.changePage.bind(this);
    this.handleEnter = this.handleEnter.bind(this);

    var ow = window.outerWidth;

    this.state = {
        ow: ow,
        params: default_params,
        citizens: [],
        showForm: false,
        table: false,
        showfilter:false,
        page: 1,
        rowsPerPage: 10,
        total: 0,
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleEnter, false);
    states.map((state, index) => {
      states_array[state.id] = state.name;

      return true;
    });

    this.getData(1);
    window.addEventListener("resize", this.changeWidth);
  }

  componentDidUpdate() {
    if (this.props.reload === true) {
      this.getData(1);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleEnter, false);
    window.removeEventListener("resize", this.changeWidth);
  }

  handleEnter(e) {
    if (e.key === "Enter") {
     e.preventDefault();
     this.getData(1);
    }
  }

  changeWidth() {
    var ow = window.outerWidth;

    this.setState({ow: ow});
  }

  onChange(d, m) {
    var params = {...this.state.params};
    params[m] = d;

    this.setState({ params: params });
  }

  getData(page) {
    const { oib, name, last_name, address } = this.state.params;

    this.props.reloaded();

    var u = 'citizens?oib=' + oib + '&name=' + name + '&last_name=' + last_name + '&address=' + address + '&page=' + page + '&limit=' + this.state.rowsPerPage;
    const requestOptions = {
      method: 'GET',
      headers: { ...service.getSignedHeaders('GET', u) },
      body: null,
    };

    fetch(process.env.REACT_APP_API + u, requestOptions)
    .then(response => response.json())
    .then(
      data => {
        if (data.errorCode === 100001) {
          service.logout();
          window.location.href = '/';
        } else {
          this.setState({citizens: data.citizens, table: true, page: page, total: data.total });
        }
      }
    );
  }

  toggleFilter() {
    this.setState({showfilter: !this.state.showfilter});
  }

  clearFilter() {
    this.setState({params: default_params});
  }

  changePage(p) {
    var page = this.state.page + p;

    this.getData(page);
  }

  render() {
    const { params, ow,page, rowsPerPage, citizens, total } = this.state;
    const pages = Math.ceil(total / rowsPerPage);

    const pinfo = <PaginationItem>
      <PaginationLink tag="button" style={{border: 0, color: 'black', margin: '0px 10px'}}>{(page-1) * rowsPerPage} - {page * rowsPerPage > total ? total : page * rowsPerPage} of {total}</PaginationLink>
    </PaginationItem>

    var filter_col_width = ow > 1500 ? 2 : 4;

    return (
      <>
      { this.state.table ? 
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Lista korisnika toplih obroka</CardTitle>
                  { this.props.role_id === 2 &&
                  <Button
                    className="btn-round"
                    color="alert"
                    type="button"
                    onClick={() => this.props.editHandler(0, 'edit')}
                  >
                    Dodavanje novog građana
                  </Button>
                  }
                  <Button
                    className="btn-round"
                    color="alert"
                    type="button"
                    onClick={() => this.toggleFilter()}
                  >
                    Filtriranje
                  </Button>
                </CardHeader>
                <CardBody>
                { this.state.showfilter &&
                  <Row>
                    { filter_col_width === 2 && <Col md="1"></Col> }
                    <Col md={filter_col_width}>
                        <FormGroup>
                        <label>OIB</label>
                        <Input
                            id="oib"
                            value={params.oib}
                            onChange={(d) => this.onChange(d.currentTarget.value, "oib")}
                            placeholder=""
                            type="text"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md={filter_col_width}>
                        <FormGroup>
                        <label>Prezime</label>
                        <Input
                            id="last_name"
                            value={params.last_name}
                            onChange={(d) => this.onChange(d.currentTarget.value, "last_name")}
                            placeholder=""
                            type="text"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md={filter_col_width}>
                        <FormGroup>
                        <label>Ime</label>
                        <Input 
                            value={params.name}
                            onChange={(d) => this.onChange(d.currentTarget.value, "name")}
                            placeholder="" 
                            type="text" 
                            id="name"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md={filter_col_width}>
                        <FormGroup>
                        <label>Adresa</label>
                        <Input 
                            value={params.address}
                            onChange={(d) => this.onChange(d.currentTarget.value, "address")}
                            placeholder="" 
                            type="text" 
                            id="address"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md={filter_col_width}>
                      <Button
                          className="btn-round inline"
                          color="alert"
                          type="button"
                          onClick={() => this.clearFilter()}
                      >
                          Očisti
                      </Button>
                      <Button
                          className="btn-round inline"
                          color="alert"
                          type="button"
                          onClick={() => this.getData(1)}
                      >
                          Traži
                      </Button>
                    </Col>
                  </Row>
                  }
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Prezime i ime</th>
                        <th>OIB</th>
                        <th>Adresa</th>
                        <th>Grad</th>
                        <th className="text-center actionsColumn">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        citizens.length === 0 ?
                          <tr>
                            <td colSpan={4} className="text-center">Nema rezultata</td>
                          </tr>
                        :
                          citizens.map((citizen, index) => {
                            return (
                              <tr key={index}>
                                <td>{citizen.last_name + " " + citizen.name}</td>
                                <td>{citizen.oib}</td>
                                <td>{citizen.street}</td>
                                <td>{citizen.city}</td>
                                <td className="text-center">
                                  { this.props.role_id === 2 ?
                                  <>
                                    <i className="far fa-edit text-gray-dark actions" onClick={() => this.props.editHandler(citizen.id)} data-tip data-for={"edit" + index} />
                                    <ReactTooltip id={"edit" + index} place="top" effect="solid">Ažuriranje {citizen.name + " " + citizen.last_name}</ReactTooltip>
                                    <i className="far fa-trash-alt text-gray-dark actions" onClick={() => this.props.deleteHandler(citizen.id, 'Brisanje ' + citizen.name + " " + citizen.last_name)} data-tip data-for={"delete" + index} />
                                    <ReactTooltip id={"delete" + index} place="top" effect="solid">Brisanje {citizen.name + " " + citizen.last_name}</ReactTooltip>
                                  </>
                                  :
                                  <>
                                    <i className="far fa-eye text-gray-dark actions" onClick={() => this.props.editHandler(citizen.id)} data-tip data-for={"edit" + index} />
                                    <ReactTooltip id={"edit" + index} place="top" effect="solid">Pregled građana {citizen.name + " " + citizen.last_name}</ReactTooltip>
                                  </>
                                  }
                                </td>
                              </tr>    
                            )
                          })
                      }
                    </tbody>
                  </Table>
                  <nav style={{float: 'right'}}>
                    <Pagination>
                      <PaginationItem onClick={() => page > 1 ? this.changePage(-1) : null }>
                        <PaginationLink disabled={page > 1 ? '': true}>
                          Prethodna
                        </PaginationLink>
                      </PaginationItem>
                      {pinfo}
                      <PaginationItem onClick={() => page < pages ? this.changePage(1) : null}>
                        <PaginationLink next tag="button" disabled={page < pages ? '': true}>
                          Sljedeća
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem></PaginationItem>
                    </Pagination>
                  </nav>
                  <div style={{clear: 'both', float:'right'}}>Napomena: maksimalni broj rezultata je 100. Za detaljnije pretraživanje koristite filtriranje.</div>
                </CardBody>
              </Card>
      :
        <Loader
          type="Rings"
          className="loader"
          height={100}
          width={100}
 
        />
      }
      </>
    );
  }
}

export default Citizens;
