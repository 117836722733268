/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert } from "reactstrap";

import MainForm from "components/Form";
import Citizens from "views/Citizens";
import Users from "views/Users";
import Meals from "views/Meals";
import Audit from "views/Audit";
import Reports from "views/Reports";

import { service } from "services";

let Module = null;
let pathname = "";
let data = {};

class Lists extends React.Component {
  constructor(props) {
    super(props);

    pathname = props.location.pathname.replace("/admin/", "");

    switch (pathname) {
      case "reports":
        Module = Reports
        break;
      case "audit":
        Module = Audit;
        break;
      case "meals":
        Module = Meals;
        break;
      case "citizens":
        Module = Citizens;
        break;
      case "users":
        Module = Users;
        break;
      default:
        break;
      }

    this.editHandler = this.editHandler.bind(this);
    this.cancelHandler = this.cancelHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.escFunction = this.escFunction.bind(this);
    this.reloaded = this.reloaded.bind(this);
    this.onChange = this.onChange.bind(this);
    this.confirmModal = this.confirmModal.bind(this);
    this.onAlertDismiss = this.onAlertDismiss.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.state = {
        showForm: false,
        form: '',
        table: true,
        edit_id: 0,
        reload: true,
        modal: false,
        modalTitle: '',
        modalBody: '',
        modalConfirm: '',
        action: '',
        error: '',
        top: 0,
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    document.getElementsByClassName("main-panel")[0].addEventListener('scroll', this.listenToScroll);
  }

  escFunction(event){
    if(event.keyCode === 27 && this.state.showForm) {
      this.cancelHandler();
    }
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
    document.getElementsByClassName("main-panel")[0].removeEventListener('scroll', this.listenToScroll);
  }

  listenToScroll = () => {
    var elem = document.getElementsByClassName("main-panel")[0];
    const winScroll = elem.scrollTop;
  
    this.setState({
      top: winScroll,
    })
  }

  onChange(d, m) {
    data[m] = d;
  }

  setData(d) {
    data = d;
  }

  reloaded() {
      this.setState({ reload: false });
  }

  editHandler(id, f) {
      this.setState({ table: !this.state.table, showForm: !this.state.showForm, edit_id: id, action: 'edit', reload: false, form: f ? f : '' });
  }

  cancelHandler(f) {
    if (this.state.error !== '') {
      this.closeModal();
    } else if (f) {
      this.confirmCancel();
    } else {
      this.setState({ modal: true, modalTitle: 'Odustati?', modalBody: '', modalConfirm: 'Zatvori', action: 'cancel', cancel: true });
    }
  }

  closeModal() {
    this.setState({ modal: false, error: '' });
  }

  confirmCancel() {
    this.setState({ modal: false, action: '', showForm: false, table:true, edit_id: 0, reload: false, form: '' });
  }

  submitHandler(title) {
    if (!title) title = "Snimanje";

    var errors = '';
    var confirm_errors = '';
    var error_message = '';

    var mandatory = document.getElementsByClassName("mandatory");

    for (var i = 0; i < mandatory.length; i++) {
      var field = mandatory[i];

      var this_value = '';
      var this_id = field.id;
      var this_name = field.parentElement.getElementsByTagName("label")[0].innerText;

      if (field.value) {
        this_value = field.value;
        if (field.getAttribute("type") === "checkbox") this_value = field.checked ? 1 : 0;
      } else {
        var f = field.getElementsByClassName("rw-dropdown-list-autofill");
        if (f[0]) {
          this_id = this_id.replace("_input", "");
          this_value = data[this_id];
        }
      }

      if (this_value === '' && !(this_id === 'password' && this.state.edit_id > 0)) {
        if (errors !== '') errors += ', ';
        errors += this_name;
      }
    }

    var confirm = document.getElementsByClassName("confirm");
    for (i = 0; i < confirm.length; i++) {
      field = confirm[i];

      this_value = '';
      this_id = field.id;
      this_name = field.parentElement.getElementsByTagName("label")[0].innerText;

      if (field.value) {
        this_value = field.value;
        if (field.getAttribute("type") === "checkbox") this_value = field.checked ? 1 : 0;
      } else {
        f = field.getElementsByClassName("rw-dropdown-list-autofill");
        if (f[0]) {
          this_id = this_id.replace("_input", "");
          this_value = data[this_id];
        }
      }

      var confirm_id = this_id + "_confirm";
      var confirm_field = document.getElementById(confirm_id);
      var confirm_value = '';

      if (confirm_field.value) {
        confirm_value = confirm_field.value;
        if (confirm_field.getAttribute("type") === "checkbox") confirm_value = confirm_field.checked ? 1 : 0;
      } else {
        f = confirm_field.getElementsByClassName("rw-dropdown-list-autofill");
        if (f[0]) {
          this_id = this_id.replace("_input", "");
          confirm_value = data[this_id];
        }
      }

      var changed_id = this_id + "_changed";
      var changed_field = document.getElementById(changed_id).value;

      if (this_value !== confirm_value && changed_field === "true") {
        if (confirm_errors !== '') confirm_errors += ', ';
        confirm_errors += this_name;
      }
    }

    if (data['user_organization']) {
      if (data['user_organization'] === 'Ostalo') {
        var user_organization_rest = '';

        if (data['user_organization_rest']) user_organization_rest = data['user_organization_rest'];

        if (user_organization_rest === '') {
          if (errors !== '') errors += ', ';
          errors += 'Opis organizacije';
        }
      }
    }

    if (errors !== '') {
      error_message = 'Obavezna polja: ' + errors;
    }
    if (confirm_errors !== '') {
      if (error_message !== '') error_message += ", ";
      error_message += 'Potvrdite vrijednosti za: ' + confirm_errors;
    }
  
    if (data['email']) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if ( !re.test(data['email']) ) {
        if (error_message !== '') error_message += ', ';
        error_message += 'Neispravna email adresa!';
      }
    }

    if (pathname === 'citizens') {
      var osnova = 0;
      if (data['osn_1_ostecen_object_narancasto']) osnova = 1;
      if (data['osn_2_ostecen_object_crveno']) osnova = 1;
      if (data['osn_3_korisnik_mzn']) osnova = 1;
      if (data['osn_4_trudnica']) osnova = 1;
      if (data['osn_5_invalid']) osnova = 1;
      if (data['osn_6_ostalo'] && data['osn_6_opis'] && data['osn_6_opis'] !== '') osnova = 1;

      if (osnova === 0) {
        if (error_message !== '') error_message += ', ';
        error_message += 'Korisnik nema osnovu za dodjelu toplih obroka!';
      }
    }

    if (error_message !== '') {
      var thisModalBody = error_message;
      this.setState({ modal: false, error: thisModalBody });
      setTimeout(this.onAlertDismiss, 3000);
    } else {
      this.setState({ modal: true, modalTitle: title, modalBody: '', modalConfirm: 'Snimi', action: 'save', error: '', reload: false, cancel: true });
    }
  }

  deleteHandler(id, title) {
    if (!title) title = "Delete";

    this.setState({ modal: true, modalTitle: title, modalBody: '', modalConfirm: 'Obriši', action: 'delete', edit_id: id, error: '', reload: false, cancel: true });
  }

  confirmModal() {
    if (this.state.action === 'cancel') {
      this.confirmCancel();
    }

    if (this.state.action === 'errors') {
      this.closeModal();
    }

    var u = '';
    if (this.state.action === 'delete') {
      u = pathname + '?id=' + this.state.edit_id;
      const requestOptions = {
          method: 'DELETE',
          headers: { ...service.getSignedHeaders('DELETE', u), 'Content-Type': 'application/json' },
      };

      fetch(process.env.REACT_APP_API + u, requestOptions)
        .then(response => response.json())
        .then(resp => {
            if (resp.errorCode === 100001) {
              service.logout();
              window.location.href = '/';
            } else if (resp.errorCode > 0) {
              this.setState({ showForm: false, table:true, edit_id: 0, reload: true, modal: false, error: resp.errorMessage });
            } else {
              this.setState({ showForm: false, table:true, edit_id: 0, reload: true, modal: false, error: '' });
            }
        });
      }

      if (this.state.action === 'save') {
        var fields = document.getElementsByClassName("inputdata");
        var json = {};
        for (var i = 0; i < fields.length; i++) {
            var field = fields[i];
            var id = field.getAttribute("id");

            if (field.value) {
              var value = field.value;
              if (field.getAttribute("type") === "checkbox") value = field.checked ? 1 : 0;
    
              json[id] = value;
            } else {
              var f = field.getElementsByClassName("rw-dropdown-list-autofill");
              if (f[0]) {
                id = id.replace("_input", "");
                json[id] = data[id];
              }
            }

            if (id === 'user_organization_rest' && value !== '') {
              json['user_organization'] = value;
            }
        }

        u = pathname + '?id=' + this.state.edit_id;
        const requestOptions = {
            method: 'POST',
            headers: { ...service.getSignedHeaders('POST', u, json), 'Content-Type': 'application/json' },
            body: JSON.stringify(json)
        };
    
        fetch(process.env.REACT_APP_API + u, requestOptions)
        .then(response => response.json())
        .then(resp => {
          if (resp.errorCode === 100001) {
            service.logout();
            window.location.href = '/';
          } else if (resp.errorCode > 0) {
            this.setState({ modal: false, error: resp.errorMessage });
            setTimeout(this.onAlertDismiss, 3000);
          } else {
            this.props.onUpdate();
            this.setState({ showForm: false, table:true, edit_id: 0, reload: true, modal: false, error: '' });
          }
        });   
      }
  }

  onAlertDismiss() {
    this.setState({ error: '' });
  }

  render() {
    var { showForm, table, edit_id, reload, modal, modalBody, modalTitle, modalConfirm, cancel, error, action, form, top } = this.state;

    if (modalBody === '') modalBody = 'Jeste li sigurni da želite nastaviti?';

    return (
      <>
        { error !== "" &&
        <Alert color="danger" isOpen={true} style={{top: top + 'px'}} toggle={this.onAlertDismiss} >
          {error}
        </Alert>
        }
        <div className="content">
          {showForm && 
            <MainForm 
              role_id={this.props.role_id}
              form={form} 
              submitHandler={this.submitHandler} 
              cancelHandler={this.cancelHandler} 
              module={pathname} action={action} 
              onChange={this.onChange} 
              setData={this.setData} 
              id={edit_id} 
            />
          }
          <Row style={{display: table ? '': 'none'}}>
            <Col md="12">
              <Module 
                role_id={this.props.role_id}
                editHandler={this.editHandler} 
                deleteHandler={this.deleteHandler} 
                reload={reload} 
                reloaded={this.reloaded}
              />
            </Col>
          </Row>
        </div>
        <Modal isOpen={modal} className="">
            <ModalHeader toggle={this.closeModal}>{modalTitle}</ModalHeader>
            <ModalBody>{modalBody}</ModalBody>
            <ModalFooter>
                { cancel && <Button color="secondary" onClick={this.closeModal}>Odustani</Button>}
                {' '}
                <Button color="primary" onClick={this.confirmModal}>{modalConfirm}</Button>
            </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default Lists;
