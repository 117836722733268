const roles = [
    {
        "id":"1",
        "name":"Administrator sustava"
     },
     {
        "id":"2",
        "name":"Izdavatelj obroka"
     },
     {
        "id":"3",
        "name":"Preglednik podataka"
     },
 ];

 export {roles};