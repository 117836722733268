import React from "react";

import {
    Row,
    Col,
    FormGroup,
    Input,
    Form,
    Label,
    Button,
    Alert
} from "reactstrap";

import { states } from 'variables/states';
import { service } from "services";

import { DropdownList } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import hr from "date-fns/locale/hr";
registerLocale('hr', hr);

let default_data = {
    oib: '',
    name: '',
    last_name: '',
    date_of_birth: '',
    gender: '',
    state: 'Sisačko-moslavačka',
    residence_state: 'Sisačko-moslavačka',
    county: '',
    residence_county: '',
    city_id: '',
    city: '',
    residence_city: '',
    post_code: '',
    residence_post_code: '',
    street: '',
    temp_state: 'Sisačko-moslavačka',
    temp_city: '',
    temp_city_id: '',
    temp_post_code: '',
    temp_street: '',
    email: '',
    email_confirm: "",
    email_initial: "",
    email_changed: false,
    phone_number: '',
    phone_number_confirm: "",
    phone_number_initial: "",
    phone_number_changed: false,
    osn_1_ostecen_object_narancasto: '',
    osn_2_ostecen_object_crveno: '',
    osn_3_korisnik_mzn: '',
    osn_4_trudnica: '',
    osn_5_invalid: '',
    osn_6_ostalo: '',
    osn_6_opis: '',
    comment: '',
    active_inactive: 1,
  };

export default class CitizensForm extends React.Component {
    constructor(props) {
        super(props);
  
        const id = this.props.id ? this.props.id : 0;

        this.onChange = this.onChange.bind(this);
        this.onAlertDismiss = this.onAlertDismiss.bind(this);
        this.getCitizen = this.getCitizen.bind(this);

        this.state = {
          id: id,
          data: { ...default_data },
          reload: false,
          error: '',
          top: 0,
          getting: false,
          form: this.props.form,
        };
    }

    componentDidMount() {
        this.setState({data: { ...default_data }});
        this.getData(this.state.id);
        document.getElementsByClassName("main-panel")[0].addEventListener('scroll', this.listenToScroll);
    }

    componentDidUpdate() {
        if (this.props.id !== this.state.id && this.props.action === 'edit') {
            this.setState({id: this.props.id, reload: false, data: { ...default_data }});
            this.getData(this.props.id);
        }

        if (this.props.form !== this.state.form) {
            this.setState({form: this.props.form, data: { ...default_data }});
        }
    }

    componentWillUnmount() {
        document.getElementsByClassName("main-panel")[0].removeEventListener('scroll', this.listenToScroll);
    }

    listenToScroll = () => {
        var elem = document.getElementsByClassName("main-panel")[0];
        const winScroll = elem.scrollTop;
      
        this.setState({
          top: winScroll,
        })
      }

    onChange(d, m) {
        this.props.onChange(d, m);
        var data = {...this.state.data};
        data[m] = d;

        if (m === 'email' || m === 'email_confirm') {
            data.email_changed = data.email !== data.email_initial ? true: false;

            if (data.email === data.email_initial && data.email_confirm === '') data.email_changed = false;
        }
        if (m === 'phone_number' || m === 'phone_number_confirm') {
            data.phone_number_changed = data.phone_number !== data.phone_number_initial ? true: false;

            if (data.phone_number === data.phone_number_initial && data.phone_number_confirm === '') data.phone_number_changed = false;
        }
        if (m === 'password' || m === 'password_confirm') {
            data.password_changed = data.password !== data.password_initial ? true: false;

            if (data.password === data.password_initial && data.password_confirm === '') data.password_changed = false;
        }

        this.setState({ data: data });
        this.props.setData(data);
    }

    getData(id) {
        if (id > 0) {
            var u = 'citizens?id=' + id;
            const requestOptions = {
              method: 'GET',
              headers: { ...service.getSignedHeaders('GET', u) },
              body: null,
            };
        
            fetch(process.env.REACT_APP_API + u, requestOptions)
            .then(response => response.json())
            .then(
                data => {
                    if (data.errorCode === 100001) {
                        service.logout();
                        window.location.href = '/';
                    } else if (data.errorCode > 0) {
                        this.setState({ error: data.errorMessage });
                        setTimeout(this.onAlertDismiss, 3000);
                    } else {
                        var new_data = data.citizens[0];

                        new_data.email_initial = data.citizens[0].email;
                        new_data.email_changed = false;
                        new_data.phone_number_initial = data.citizens[0].phone_number;
                        new_data.phone_number_changed = false;

                        this.setState({data: new_data});
                        this.props.setData(new_data);
                    }
                }
            );
        } else {
            this.setState({data: {...default_data}});
            this.props.setData({ ...default_data });
        }
    }

    onAlertDismiss() {
        this.setState({ error: '' });
      }

    getCitizen() {
        const { oib } = this.state.data;

        this.setState({ getting: true, data: {...default_data} });

        if (oib.length === 11) {
            var u = 'getcitizen?oib=' + oib;
            const requestOptions = {
              method: 'GET',
              headers: { ...service.getSignedHeaders('GET', u) },
              body: null,
            };
        
            fetch(process.env.REACT_APP_API + u, requestOptions)
            .then(response => response.json())
            .then(
                resp => {
                    if (resp.errorCode === 100001) {
                        service.logout();
                        window.location.href = '/';
                    } else if (resp.errorCode > 0) {
                        this.setState({ error: resp.errorMessage, getting: false });
                        setTimeout(this.onAlertDismiss, 3000);
                    } else {
                        var data = {...this.state.data};

                        if (resp.podaciOsoba) {
                            data['oib'] = oib;
                            data['name'] = resp.podaciOsoba.ime;
                            data['last_name'] = resp.podaciOsoba.prezime;
                            data['date_of_birth'] = new Date(resp.podaciOsoba.datumRodenja);
                            data['city'] = resp.podaciOsoba.prebivalisteBoraviste.adresaMjesto.nazivMjesto;
                            data['county'] = resp.podaciOsoba.prebivalisteBoraviste.adresaOpcina.nazivOpcina;
                            data['street'] = resp.podaciOsoba.prebivalisteBoraviste.ulicaKbr;

                            data['residence_county'] = '';
                            data['residence_city'] = '';
                            if (resp.podaciOsoba.prebivalisteBoraviste.adresaOpcinaBor) {
                                data['residence_county'] = resp.podaciOsoba.prebivalisteBoraviste.adresaOpcinaBor.nazivOpcina;
                                data['residence_city'] = resp.podaciOsoba.prebivalisteBoraviste.adresaMjestoBor.nazivMjesto;    
                            }

                            this.setState({ data: data, getting: false });
                            this.props.setData(data);
                        } else {
                            this.setState({ error: "Nema podataka za navedeni OIB!", getting: false });
                            setTimeout(this.onAlertDismiss, 3000);
                        }
                    }
                }
            );
        } else {
            this.setState({ error: "Neispravan OIB!", getting: false });
            setTimeout(this.onAlertDismiss, 3000);
        }
    }

    render() {
        const { data, error, top, getting } = this.state;

        return (
            <>
            { error !== "" &&
            <Alert color="danger" isOpen={true} style={{top: top + 'px'}} toggle={this.onAlertDismiss} >
              {error}
            </Alert>
            }
            <Form>
                <Row>
                    <Col md="2">
                        <FormGroup>
                        <label>OIB</label>
                        <Input
                            id="oib"
                            value={data.oib}
                            onChange={(d) => this.onChange(d.currentTarget.value, "oib")}
                            placeholder=""
                            type="text"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    {this.props.role_id === 2 && 
                    <Col>
                        <Button
                            className="btn-round"
                            color="alert"
                            type="button"
                            style={{marginTop: '24px'}}
                            onClick={() => this.getCitizen()}
                            disabled={getting ? true : false}
                        >
                            { getting ? "Učitavam..." : "Učitaj podatke" }
                        </Button>
                    </Col>
                    }
                </Row>
                <Row>
                    <Col md="2">
                        <FormGroup>
                        <label>Prezime</label>
                        <Input
                            id="last_name"
                            value={data.last_name}
                            placeholder=""
                            type="text"
                            readOnly
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup>
                        <label>Ime</label>
                        <Input
                            id="name"
                            value={data.name}
                            readOnly
                            placeholder=""
                            type="text"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup>
                        <label>Datum rođenja</label>
                        <DatePicker
                                locale="hr"
                                id="date_of_birth"
                                disabledKeyboardNavigation
                                dateFormat="d.M.yyyy"
                                readOnly
                                className="form-control inputdata"
                                highlightDates={[new Date()]}
                                showTimeInput={false}
                                autoComplete="new-password"
                                selected={data.date_of_birth ? new Date(data.date_of_birth) : ''}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="1">
                        <FormGroup check>
                            <Label check>
                                <Input
                                    id="active_inactive"
                                    type="checkbox"
                                    className="inputdata"
                                    checked={ data.active_inactive === 1 ? true : false }
                                    onChange={(d) => this.onChange(d.currentTarget.checked ? 1 : 0, "active_inactive")}
                                />
                                Aktivan
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="3">
                        <FormGroup>
                        <label>Adresa</label>
                        <Input
                            id="street"
                            value={data.street}
                            readOnly
                            placeholder=""
                            type="text"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup>
                        <label>Grad</label>
                        <Input
                            id="city"
                            value={data.city}
                            readOnly
                            placeholder=""
                            type="text"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup>
                        <label>Općina</label>
                        <Input
                            id="county"
                            value={data.county}
                            readOnly
                            placeholder=""
                            type="text"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup>
                        <label>Poštanski broj</label>
                        <Input
                            id="post_code"
                            value={data.post_code}
                            onChange={(d) => this.onChange(d.currentTarget.value, "post_code")}
                            placeholder="" 
                            type="text"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup>
                        <label>Županija</label>
                        <DropdownList filter
                            id="state"
                            data={states}
                            valueField='id'
                            defaultValue={data.state}
                            value={data.state}
                            onChange={(d) => this.onChange(d.id, "state")}
                            textField='name'
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h5 className="card-divider-title">Boravište</h5>
                    </Col>
                </Row>
                <Row>
                <Col md="2">
                        <FormGroup>
                        <label>Grad</label>
                        <Input
                            id="residence_city"
                            value={data.residence_city}
                            readOnly
                            placeholder=""
                            type="text"
                            className="inputdata"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup>
                        <label>Općina</label>
                        <Input
                            id="residence_county"
                            value={data.residence_county}
                            readOnly
                            placeholder=""
                            type="text"
                            className="inputdata"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup>
                        <label>Poštanski broj</label>
                        <Input
                            id="residence_post_code"
                            value={data.residence_post_code}
                            onChange={(d) => this.onChange(d.currentTarget.value, "residence_post_code")}
                            placeholder="" 
                            type="text"
                            className="inputdata"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup>
                        <label>Županija</label>
                        <DropdownList filter
                            id="residence_state"
                            data={states}
                            valueField='id'
                            defaultValue={data.residence_state}
                            value={data.residence_state}
                            onChange={(d) => this.onChange(d.id, "residence_state")}
                            textField='name'
                            className="inputdata"
                        />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h5 className="card-divider-title">Kontakt podaci (opcionalno)</h5>
                    </Col>
                </Row>
                <Row>
                    <Col md="3">
                        <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                            Email
                        </label>
                        <Input 
                            value={data.email}
                            onChange={(d) => this.onChange(d.currentTarget.value, "email")}
                            placeholder="" 
                            type="email" 
                            id="email"
                            className="inputdata confirm"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                            Potvrda email adrese
                        </label>
                        <Input 
                            value={data.email_confirm}
                            onChange={(d) => this.onChange(d.currentTarget.value, "email_confirm")}
                            placeholder="" 
                            autoComplete="new-password"
                            type="email" 
                            id="email_confirm"
                            className="inputdata"
                        />
                        <Input type="hidden" id="email_changed" value={data.email_changed} />
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup>
                        <label>Broj telefona</label>
                        <Input 
                            value={data.phone_number}
                            onChange={(d) => this.onChange(d.currentTarget.value, "phone_number")}
                            placeholder="" 
                            type="text" 
                            id="phone_number"
                            className="inputdata confirm"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup>
                        <label>Potvrda broja telefona</label>
                        <Input
                            id="phone_number_confirm"
                            value={data.phone_number_confirm}
                            onChange={(d) => this.onChange(d.currentTarget.value, "phone_number_confirm")}
                            placeholder=""
                            autoComplete="new-password"
                            onFocus={this.onFocus}
                            type="text"
                            className="inputdata"
                        />
                        <Input type="hidden" id="phone_number_changed" value={data.phone_number_changed} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h5 className="card-divider-title">Privremena adresa (opcionalno)</h5>
                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <FormGroup>
                        <label>Adresa</label>
                        <Input
                            id="temp_street"
                            value={data.temp_street}
                            onChange={(d) => this.onChange(d.currentTarget.value, "temp_street")}
                            placeholder=""
                            type="text"
                            className="inputdata"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup>
                        <label>Grad</label>
                        <Input
                            id="temp_city"
                            value={data.temp_city}
                            onChange={(d) => this.onChange(d.currentTarget.value, "temp_city")}
                            placeholder=""
                            type="text"
                            className="inputdata"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup>
                        <label>Poštanski broj</label>
                        <Input
                            id="temp_post_code"
                            value={data.temp_post_code}
                            onChange={(d) => this.onChange(d.currentTarget.value, "temp_post_code")}
                            placeholder="" 
                            type="text"
                            className="inputdata"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup>
                        <label>Županija</label>
                        <DropdownList filter
                            id="temp_state"
                            data={states}
                            valueField='id'
                            defaultValue={data.temp_state}
                            value={data.temp_state}
                            onChange={(d) => this.onChange(d.id, "temp_state")}
                            textField='name'
                            className="inputdata"
                        />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h5 className="card-divider-title">Kategorija</h5>
                    </Col>
                </Row>
                <Row className="checkboxes">
                    <Col md="2">
                        <FormGroup check>
                            <Label check>
                                <Input
                                    id="osn_1_ostecen_object_narancasto"
                                    type="checkbox"
                                    className="inputdata"
                                    checked={ data.osn_1_ostecen_object_narancasto === 1 ? true : false }
                                    onChange={(d) => this.onChange(d.currentTarget.checked ? 1 : 0, "osn_1_ostecen_object_narancasto")}
                                />
                                Narančasti objekt
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup check>
                            <Label check>
                                <Input
                                    id="osn_2_ostecen_object_crveno"
                                    type="checkbox"
                                    className="inputdata"
                                    checked={ data.osn_2_ostecen_object_crveno === 1 ? true : false }
                                    onChange={(d) => this.onChange(d.currentTarget.checked ? 1 : 0, "osn_2_ostecen_object_crveno")}
                                />
                                Crveni objekt
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup check>
                            <Label check>
                                <Input
                                    id="osn_3_korisnik_mzn"
                                    type="checkbox"
                                    className="inputdata"
                                    checked={ data.osn_3_korisnik_mzn === 1 ? true : false }
                                    onChange={(d) => this.onChange(d.currentTarget.checked ? 1 : 0, "osn_3_korisnik_mzn")}
                                />
                                Korisnik MZN
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup check>
                            <Label check>
                                <Input
                                    id="osn_4_trudnica"
                                    type="checkbox"
                                    className="inputdata"
                                    checked={ data.osn_4_trudnica === 1 ? true : false }
                                    onChange={(d) => this.onChange(d.currentTarget.checked ? 1 : 0, "osn_4_trudnica")}
                                />
                                Trudnica
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup check>
                            <Label check>
                                <Input
                                    id="osn_5_invalid"
                                    type="checkbox"
                                    className="inputdata"
                                    checked={ data.osn_5_invalid === 1 ? true : false }
                                    onChange={(d) => this.onChange(d.currentTarget.checked ? 1 : 0, "osn_5_invalid")}
                                />
                                Invalid
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup check>
                            <Label check>
                                <Input
                                    id="osn_6_ostalo"
                                    type="checkbox"
                                    className="inputdata"
                                    checked={ data.osn_6_ostalo === 1 ? true : false }
                                    onChange={(d) => this.onChange(d.currentTarget.checked ? 1 : 0, "osn_6_ostalo")}
                                />
                                Ostalo
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="10"></Col>
                    <Col md="2">
                        <FormGroup style={{display: data.osn_6_ostalo === 1 ? '' : 'none'}}>
                            <label style={{display:'none'}}>Ostalo</label>
                            <Input
                                id="osn_6_opis"
                                value={data.osn_6_opis}
                                onChange={(d) => this.onChange(d.currentTarget.value, "osn_6_opis")}
                                placeholder="" 
                                type="text"
                                className="inputdata"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h5 className="card-divider-title">Napomene (opcionalno)</h5>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <FormGroup>
                        <label style={{display:'none'}}>Napomene</label>
                        <Input
                            id="comment"
                            type="textarea"
                            value={data.comment}
                            onChange={(d) => this.onChange(d.currentTarget.value, "comment")}
                            className="inputdata"
                        />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
            </>
          );
    }
};
