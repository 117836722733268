import React from "react";

import {
    Row,
    Col,
    FormGroup,
    Input,
    Form,
    Card,
    CardHeader,
    CardTitle,
    Table,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
} from "reactstrap";

import { meal_types } from 'variables/meal_types';
import { service } from "services";

import { DropdownList } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';

import QrReader from 'react-qr-reader';
import CardBody from "reactstrap/lib/CardBody";

var to;

var default_params = { oib: '', name: '', last_name: '', address: '', meal_type_id: 2 };

export default class Meals extends React.Component {
    constructor(props) {
        super(props);
  
        this.onChange = this.onChange.bind(this);
        this.getData = this.getData.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.selectCitizen = this.selectCitizen.bind(this);
        this.removeCitizen = this.removeCitizen.bind(this);
        this.handleEnter = this.handleEnter.bind(this);
        this.issueMeal = this.issueMeal.bind(this);
        this.cancelConfirmIssue = this.cancelConfirmIssue.bind(this);
        this.confirmIssue = this.confirmIssue.bind(this);
        this.onAlertDismiss = this.onAlertDismiss.bind(this);
        this.changeWidth = this.changeWidth.bind(this);

        default_params.meal_type_id = this.getDefaultMealType();

        var ow = window.outerWidth;

        this.state = {
          ow: ow,
          params: default_params,
          selected: [],
          filtered: [],
          showfiltered: false,
          reload: false,
          result: 'No result',
          qr: false,
          showconfirmissue: false,
          error: '',
          top: 0,
          alertcolor: 'danger',
        };
    }

    componentDidMount() {
      document.addEventListener("keydown", this.handleEnter, false);
      document.getElementsByClassName("main-panel")[0].addEventListener('scroll', this.listenToScroll);
      window.addEventListener("resize", this.changeWidth);
    }

    componentWillUnmount(){
      document.removeEventListener("keydown", this.handleEnter, false);
      document.getElementsByClassName("main-panel")[0].removeEventListener('scroll', this.listenToScroll);
      window.removeEventListener("resize", this.changeWidth);
    }

    componentDidUpdate() {
        if (this.props.id !== this.state.id && this.props.action === 'edit') {
            this.setState({id: this.props.id, reload: false});
        }
    }

    changeWidth() {
      var ow = window.outerWidth;

      this.setState({ow: ow});
    }

    listenToScroll = () => {
      var elem = document.getElementsByClassName("main-panel")[0];
      const winScroll = elem.scrollTop;
    
      this.setState({
        top: winScroll,
      })
    }

    getDefaultMealType() {
      var d = new Date();
      var h = d.getHours();

      return 1;
      return h > 15 ? 2 : 1;
    }

    onChange(d, m) {
      clearTimeout(to);
      var params = {...this.state.params};
      params[m] = d;

      this.setState({ params: params });
    }

    handleEnter(e) {
      if (e.key === "Enter") {
       e.preventDefault();
       this.getData();
      }
    }

    getData() {
      const { oib, name, last_name, address } = this.state.params;

      if (oib !== '' || name !== '' || last_name !== '' || address !== '') {
        this.setState({showfiltered: true});

        var u = 'citizens?oib=' + oib + '&name=' + name + '&last_name=' + last_name + '&address=' + address + '&active=1page=1&limit=100';
        const requestOptions = {
          method: 'GET',
          headers: { ...service.getSignedHeaders('GET', u) },
          body: null,
        };

        fetch(process.env.REACT_APP_API + u, requestOptions)
        .then(response => response.json())
        .then(
          data => {
            if (data.errorCode === 100001) {
              service.logout();
              window.location.href = '/';
            } else if (data.errorCode > 0) {
              this.setState({ error: data.errorMessage, alertcolor: 'danger', showconfirmissue: false });
              setTimeout(this.onAlertDismiss, 3000);
            } else {
              this.setState({filtered: data.citizens});
            }
          }
        );
      }
    }

    issueMeal() {
      this.setState({showconfirmissue: true});
    }

    cancelConfirmIssue() {
      this.setState({showconfirmissue: false});
    }

    confirmIssue() {
      const { selected, params } = this.state;

      var ids = '';
      selected.map((s) => {
        if ((s.lunch_issued === 0 && params.meal_type_id === 1) || (s.dinner_issued === 0 && params.meal_type_id === 2)) {
          if (ids !== '') ids += ',';
          ids += s.id;
        }

        return true;
      });

      if (ids !== '') {
        var u = 'issuemeal?ids=' + ids + '&meal_type_id=' + params.meal_type_id;
        const requestOptions = {
          method: 'GET',
          headers: { ...service.getSignedHeaders('GET', u) },
          body: null,
        };

        fetch(process.env.REACT_APP_API + u, requestOptions)
        .then(response => response.json())
        .then(
          data => {
            if (data.errorCode === 100001) {
              service.logout();
              window.location.href = '/';
            } else if (data.errorCode > 0) {
              this.setState({ error: data.errorMessage, alertcolor: 'danger', showconfirmissue: false });
              setTimeout(this.onAlertDismiss, 3000);
            } else {
              setTimeout(this.onAlertDismiss, 3000);
              document.getElementsByClassName("main-panel")[0].scrollTop = 0;

              default_params.meal_type_id = this.getDefaultMealType();
              this.setState({error: data.status, alertcolor: 'success', showconfirmissue: false, selected: [], filtered: [], params: default_params});
            }
          }
        );
      } else {
        setTimeout(this.onAlertDismiss, 3000);
        this.setState({ error: 'Nijedan odabrani građanin nema pravo na obrok!', alertcolor: 'danger', showconfirmissue: false });
      }
    }

    lastMeal(id) {
      var u = 'lastmeal?id=' + id;
      const requestOptions = {
        method: 'GET',
        headers: { ...service.getSignedHeaders('GET', u) },
        body: null,
      };

      fetch(process.env.REACT_APP_API + u, requestOptions)
      .then(response => response.json())
      .then(
        data => {
          if (data.errorCode === 100001) {
            service.logout();
            window.location.href = '/';
          } else if (data.errorCode > 0) {
            this.setState({ error: data.errorMessage, alertcolor: 'danger', showconfirmissue: false });
            setTimeout(this.onAlertDismiss, 3000);
          } else {
            if (data.citizens) {
              data.citizens.map((citizen) => {
                this.selectCitizen(citizen);
                return true;
              });
            }
          }
        }
      );
    }

    closeModal() {
      this.setState({showfiltered: false});
    }

    selectCitizen(c) {
      var selected = [...this.state.selected];

      if (selected.length === 100) {
        this.setState({ error: 'Moguće je maksimalno odabrati 100 osoba!', alertcolor: 'danger', showconfirmissue: false });
        setTimeout(this.onAlertDismiss, 3000);
        return;
      }

      var has = false;
      selected.map((s) => {
        if (s.id === c.id) has = true;
        return true;
      });

      if (!has) {
        selected.push(c);
        this.setState({selected: selected});
      }
    }

    removeCitizen(cid) {
      var selected = [...this.state.selected];
      var newselected = [];

      selected.map((s) => {
        if (s.id !== cid) newselected.push(s);
        return true;
      });

      this.setState({selected: newselected});
    }

    checkIfSelected(c) {
      var sel = false;
      
      this.state.selected.map((s) => {
        if (s.id === c.id) sel = true;
        return true;
      });

      return sel;
    }

    handleScan = data => {
        if (data) {
          this.setState({
            result: data
          })
        }
    }
    
    handleError = err => {
        console.error(err)
    }

    onAlertDismiss() {
      this.setState({ error: '' });
    }

    clearFilter() {
      this.setState({params: default_params});
    }

    render() {
        const { selected, filtered, showfiltered, showconfirmissue, error, top, params, ow, alertcolor } = this.state;

        var filter_col_width = ow > 1500 ? 2 : 4;

        return (
          <>
          { error !== "" &&
          <Alert color={alertcolor} isOpen={true} style={{top: top + 'px'}} toggle={this.onAlertDismiss} >
            {error}
          </Alert>
          }
          <Card>
            <CardHeader className="cardHeader">
              <CardTitle tag="h5">Izdavanje obroka</CardTitle>
            </CardHeader>
            <CardBody>
            <Form onSubmit={this.getData}>
              {this.state.qr && 
                <Row>
                    <Col md="2">
                        <QrReader
                            delay={300}
                            onError={this.handleError}
                            onScan={this.handleScan}
                            style={{ width: '100%', height: '300px', }}
                        />
                    </Col>
                </Row>
              }
                <Row style={{paddingLeft: filter_col_width === 2 ? '30px': ''}}>
                    <Col md={filter_col_width}></Col>
                    <Col md={filter_col_width}>
                        <FormGroup>
                        <label>OIB</label>
                        <Input
                            id="oib"
                            value={params.oib}
                            onChange={(d) => this.onChange(d.currentTarget.value, "oib")}
                            placeholder=""
                            type="text"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md={filter_col_width}>
                        <FormGroup>
                        <label>Prezime</label>
                        <Input
                            id="last_name"
                            value={params.last_name}
                            onChange={(d) => this.onChange(d.currentTarget.value, "last_name")}
                            placeholder=""
                            type="text"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md={filter_col_width}>
                        <FormGroup>
                        <label>Ime</label>
                        <Input 
                            value={params.name}
                            onChange={(d) => this.onChange(d.currentTarget.value, "name")}
                            placeholder="" 
                            type="text" 
                            id="name"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md={filter_col_width}>
                        <FormGroup>
                        <label>Adresa</label>
                        <Input 
                            value={params.address}
                            onChange={(d) => this.onChange(d.currentTarget.value, "address")}
                            placeholder="" 
                            type="text" 
                            id="address"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md={filter_col_width} style={{display: 'none'}}>
                      <FormGroup>
                        <label>Vrsta obroka</label>
                        <DropdownList
                            data={meal_types}
                            id="meal_type_id"
                            value={params.meal_type_id}
                            valueField='id'
                            onChange={(d) => this.onChange(d.id, "meal_type_id")}
                            textField='name'
                            className="inputdata"
                        />
                        </FormGroup>
                    </Col>
                    <Col md={filter_col_width}>
                      <Button
                          className="btn-round inline"
                          color="alert"
                          type="button"
                          onClick={() => this.clearFilter()}
                          data-tip data-for="clearfilter"
                      >
                          Očisti
                      </Button>
                      <Button
                          className="btn-round inline"
                          color="alert"
                          type="button"
                          onClick={() => this.getData()}
                          data-tip data-for="search"
                      >
                          Traži
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                        <h5 className="card-divider-title text-center">Odabrani građani</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>OIB</th>
                            <th>Prezime i ime</th>
                            <th>Adresa</th>
                            <th>Grad</th>
                            <th>Izdano</th>
                            <th className="text-center" style={{width: '100px'}}>Akcija</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          selected.length === 0 ?
                            <tr>
                              <td colSpan={7} className="text-center">Nema odabranih građana</td>
                            </tr>
                          :
                            selected.map((citizen, index) => {
                              return <tr key={index} className={((citizen.lunch_issued === 1 && params.meal_type_id === 1) || (citizen.dinner_issued === 1 && params.meal_type_id === 2)) ? 'crossed' : ''}>
                                  <td>{citizen.oib}</td>
                                  <td>{citizen.last_name + " " + citizen.name}</td>
                                  <td>{citizen.street}</td>
                                  <td>{citizen.city}</td>
                                  <td>{citizen.lunch_time}</td>
                                  <td>
                                    <Button color="danger" onClick={() => this.removeCitizen(citizen.id)}>Izbaci</Button>
                                  </td>
                                </tr>
                            })
                        }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="10"></Col>
                    <Col md="2" className="text-right">
                      { selected.length > 0 && <Button color="secondary btn-round inline" onClick={this.issueMeal}>Izdaj obrok</Button> }
                    </Col>
                  </Row>
              </Form>
              <Modal isOpen={showfiltered} className="filtered">
                <ModalHeader toggle={this.closeModal}>Filtrirani građani</ModalHeader>
                <ModalBody>
                  <div style={{textAlign: 'center', marginBottom: '20px'}}>* Odabirom opcije Ponovi, automatski će se odabrati svi građani kojima su dodjeljeni obroci zajedno sa građaninom kojem je aktivirana ta opcija.</div>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>OIB</th>
                        <th>Prezime i ime</th>
                        <th>Adresa</th>
                        <th>Grad</th>
                        <th>Izadno</th>
                        <th className="text-center" style={{width: '300px'}}>Akcija</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        filtered.length === 0 ?
                        <tr>
                          <td colSpan={7} className="text-center">Nema odabranih građana</td>
                        </tr>
                        :
                        filtered.map((citizen, index) => {
                          return <tr key={index}>
                            <td>{citizen.oib}</td>
                            <td>{citizen.last_name + " " + citizen.name}</td>
                            <td>{citizen.street}</td>
                            <td>{citizen.city}</td>
                            <td>{citizen.lunch_time}</td>
                            <td className="text-center">
                              {
                                ((citizen.lunch_issued === 1 && params.meal_type_id === 1) || (citizen.dinner_issued === 1 && params.meal_type_id === 2)) ?
                                  <Button color="primary" disabled>Iskorišteno</Button>
                                :
                                  this.checkIfSelected(citizen) ? 
                                  <Button color="primary" disabled>Odabran</Button>
                                  :
                                  <Button color="danger" onClick={() => this.selectCitizen(citizen)}>Odaberi</Button>
                              }
                                 <Button color="danger" onClick={() => this.lastMeal(citizen.id)}>Ponovi</Button>
                            </td>
                          </tr>
                        })
                      }
                    </tbody>
                  </Table>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.closeModal}>Zatvori</Button>
                </ModalFooter>
              </Modal>
              <Modal isOpen={showconfirmissue} className="">
                <ModalHeader toggle={this.cancelConfirmIssue}>Izdavanje obroka</ModalHeader>
                <ModalBody>Jeste li sigurni?</ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.cancelConfirmIssue}>Odustani</Button>
                    {' '}
                    <Button color="primary" onClick={this.confirmIssue}>Potvrdi</Button>
                </ModalFooter>
            </Modal>
            </CardBody>
          </Card>
          </>
        );
    }
};
