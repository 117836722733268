import React from "react";

import {
    Row,
    Col,
    FormGroup,
    Input,
    Form,
    Label,
} from "reactstrap";

import { organizations } from 'variables/organizations';
import { roles } from 'variables/roles';
import { service } from "services";

import { DropdownList } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';

let default_data = {
    address: "",
    city: "",
    clinic: "",
    clinics_id: 0,
    email: "",
    email_confirm: "",
    email_initial: "",
    email_changed: false,
    first_name: "",
    id: 0,
    is_counselor: 0,
    last_name: "",
    phone_number: "",
    phone_number_confirm: "",
    phone_number_initial: "",
    phone_number_changed: false,
    state: "Sisačko-moslavačka",
    username: "",
    zip: "",
    password: "",
    password_confirm: "",
    password_initial: "",
    password_changed: false,
    role_id: '',
    locked: 0,
  };

export default class UsersForm extends React.Component {
    constructor(props) {
        super(props);
  
        const id = this.props.id ? this.props.id : 0;

        this.onChange = this.onChange.bind(this);

        this.state = {
          id: id,
          data: { ...default_data },
          reload: false,
          locations: [],
        };
    }

    componentDidMount() {
        var u = 'locations';
        const requestOptions = {
          method: 'GET',
          headers: { ...service.getSignedHeaders('GET', u) },
          body: null,
        };
    
        fetch(process.env.REACT_APP_API + u, requestOptions)
        .then(response => response.json())
        .then(
            data => {
                if (data.errorCode === 100001) {
                    service.logout();
                    window.location.href = '/';
                } else {
                    this.setState({locations: data.locations});
                }
            }
        );

        this.getData(this.state.id);
    }

    componentDidUpdate() {
        if (this.props.id !== this.state.id && this.props.action === 'edit') {
            this.setState({id: this.props.id, reload: false});
            this.getData(this.props.id);
        }
    }

    onChange(d, m) {
        this.props.onChange(d, m);
        var data = {...this.state.data};
        data[m] = d;

        if (m === 'email' || m === 'email_confirm') {
            data.email_changed = data.email !== data.email_initial ? true: false;

            if (data.email === data.email_initial && data.email_confirm === '') data.email_changed = false;
        }
        if (m === 'phone_number' || m === 'phone_number_confirm') {
            data.phone_number_changed = data.phone_number !== data.phone_number_initial ? true: false;

            if (data.phone_number === data.phone_number_initial && data.phone_number_confirm === '') data.phone_number_changed = false;
        }
        if (m === 'password' || m === 'password_confirm') {
            data.password_changed = data.password !== data.password_initial ? true: false;

            if (data.password === data.password_initial && data.password_confirm === '') data.password_changed = false;
        }
        if (m === 'user_organization' && d !== 'Ostalo') {
            data.user_organization_rest = '';
        }

        this.setState({ data: data });
        this.props.setData(data);
    }

    getData(id) {
        var new_data = {...default_data};

        if (id > 0) {
            var u = 'users?id=' + id;
            const requestOptions = {
              method: 'GET',
              headers: { ...service.getSignedHeaders('GET', u) },
              body: null,
            };
        
            fetch(process.env.REACT_APP_API + u, requestOptions)
            .then(response => response.json())
            .then(
                data => {
                    if (data.errorCode === 100001) {
                        service.logout();
                        window.location.href = '/';
                    } else {
                        new_data = data.users[0];
                        new_data.password = '';

                        new_data.email_initial = data.users[0].email;
                        new_data.email_changed = false;
                        new_data.phone_number_initial = data.users[0].phone_number;
                        new_data.phone_number_changed = false;
                        new_data.password_initial = data.users[0].password;
                        new_data.password_changed = false;

                        var rest = true;
                        organizations.map((organization) => {
                            if (organization.id === new_data.user_organization) rest = false;

                            return true;
                        });

                        if (rest) {
                            new_data.user_organization_rest = new_data.user_organization;
                            new_data.user_organization = 'Ostalo';
                        }

                        this.setState({data: new_data});
                        this.props.setData(new_data);
                    }
                }
            );
        } else {
            this.setState({data: new_data});
            this.props.setData(new_data);
        }
    }

    onFocus = event => {

        if(event.target.autocomplete)
        {
          event.target.autocomplete = "whatever";
        }
     
     };

    render() {
        const { data, locations, id } = this.state;

        return (
            <Form autoComplete="new-password">
                <Row>
                    <Col md="2">
                        <FormGroup>
                        <label>Prezime</label>
                        <Input
                            id="last_name"
                            value={data.last_name}
                            onChange={(d) => this.onChange(d.currentTarget.value, "last_name")}
                            placeholder=""
                            type="text"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup>
                        <label>Ime</label>
                        <Input
                            id="name"
                            defaultValue={data.name}
                            onChange={(d) => this.onChange(d.currentTarget.value, "name")}
                            placeholder=""
                            type="text"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup>
                        <label>OIB</label>
                        <Input
                            id="oib"
                            defaultValue={data.oib}
                            onChange={(d) => this.onChange(d.currentTarget.value, "oib")}
                            placeholder=""
                            type="text"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup>
                        <label>Organizacija</label>
                        <DropdownList
                            id="user_organization"
                            data={organizations}
                            valueField='id'
                            value={data.user_organization}
                            onChange={(d) => this.onChange(d.id, "user_organization")}
                            textField='name'
                            className="inputdata"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup>
                        <label>Lokacija</label>
                        <DropdownList
                            id="location_id"
                            data={locations}
                            valueField='id'
                            value={data.location_id}
                            onChange={(d) => this.onChange(d.id, "location_id")}
                            textField='name'
                            className="inputdata"
                        />
                        </FormGroup>
                    </Col>                    
                </Row>
                <Row>
                    <Col md="6"></Col>
                    <Col md="3">
                        { data.user_organization === 'Ostalo' &&
                        <FormGroup>
                        <label>Opis organicije</label>
                        <Input
                            id="user_organization_rest"
                            defaultValue={data.user_organization_rest}
                            onChange={(d) => this.onChange(d.currentTarget.value, "user_organization_rest")}
                            placeholder=""
                            type="text"
                            className="inputdata"
                        />
                        </FormGroup>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col md="3">
                        <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                            Email adresa
                        </label>
                        <Input 
                            value={data.email}
                            onChange={(d) => this.onChange(d.currentTarget.value, "email")}
                            placeholder="" 
                            type="email" 
                            id="email"
                            className="inputdata confirm"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup>
                        <label>Broj telefona</label>
                        <Input
                            id="phone_number"
                            value={data.phone_number}
                            onChange={(d) => this.onChange(d.currentTarget.value, "phone_number")}
                            placeholder=""
                            type="text"
                            className="inputdata confirm"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup>
                        <label>Korisničko ime</label>
                        <Input
                            id="user_name"
                            defaultValue={data.user_name}
                            onChange={(d) => this.onChange(d.currentTarget.value, "user_name")}
                            autoComplete="new-password"
                            placeholder=""
                            type="text"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup>
                        <label>Lozinka</label>
                        <Input 
                            defaultValue={data.password}
                            onChange={(d) => this.onChange(d.currentTarget.value, "password")}
                            placeholder="" 
                            autoComplete="new-password"
                            type="password" 
                            id="password"
                            className="inputdata mandatory confirm"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup>
                        <label>Uloga</label>
                        <DropdownList
                            id="role_id"
                            data={roles}
                            valueField='id'
                            value={data.role_id}
                            onChange={(d) => this.onChange(d.id, "role_id")}
                            textField='name'
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="3">
                        <FormGroup style={{display: (data.email_changed || id === 0) ? '' : 'none'}}>
                        <label htmlFor="exampleInputEmail1">
                            Potvrda email adrese
                        </label>
                        <Input 
                            defaultValue={data.email_confirm}
                            onChange={(d) => this.onChange(d.currentTarget.value, "email_confirm")}
                            placeholder="" 
                            autoComplete="new-password"
                            type="email" 
                            id="email_confirm"
                            className="inputdata"
                        />
                        <Input type="hidden" id="email_changed" value={data.email_changed} />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup style={{display: (data.phone_number_changed || id === 0) ? '' : 'none'}}>
                        <label>Potvrda broja telefona</label>
                        <Input
                            id="phone_number_confirm"
                            defaultValue={data.phone_number_confirm}
                            onChange={(d) => this.onChange(d.currentTarget.value, "phone_number_confirm")}
                            placeholder=""
                            autoComplete="new-password"
                            onFocus={this.onFocus}
                            type="text"
                            className="inputdata"
                        />
                        <Input type="hidden" id="phone_number_changed" value={data.phone_number_changed} />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                    </Col>
                    <Col md="2">
                        <FormGroup style={{display: (data.password_changed || id === 0) ? '' : 'none'}}>
                        <label>Potvrda lozinke</label>
                        <Input 
                            defaultValue={data.password_confirm}
                            onChange={(d) => this.onChange(d.currentTarget.value, "password_confirm")}
                            placeholder="" 
                            autoComplete="new-password"
                            onFocus={this.onFocus}
                            type="password" 
                            id="password_confirm"
                            className="inputdata"
                        />
                        <Input type="hidden" id="password_changed" value={data.password_changed} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <FormGroup check style={{marginTop: '10px'}}>
                            <Label check>
                                <Input
                                    id="locked"
                                    type="checkbox"
                                    className="inputdata"
                                    checked={ data.locked === 1 ? true : false }
                                    onChange={(d) => this.onChange(d.currentTarget.checked ? 1 : 0, "locked")}
                                />
                                Korisinički račun zaključan
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
          );
    }
};
