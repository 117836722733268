/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Pagination, PaginationItem, PaginationLink
} from "reactstrap";

import Loader from 'react-loader-spinner';

import { service } from "services";

class Audit extends React.Component {
  constructor(props) {
    super(props);

    this.changePage = this.changePage.bind(this);
    this.getData = this.getData.bind(this);

    this.state = {
      audit: [],
      page: 1,
      rowsPerPage: 10,
      total: 0,
      table: false,
    };
  }

  componentDidMount() {
    this.getData(1);
  }

  getData(page) {
    const { rowsPerPage } = this.state;
  
    var u = 'audit?page=' + page + '&limit=' + rowsPerPage;
    const requestOptions = {
      method: 'GET',
      headers: { ...service.getSignedHeaders('GET', u) },
      body: null,
    };

    fetch(process.env.REACT_APP_API + u, requestOptions)
    .then(response => response.json())
    .then(
      data => {
        if (data.errorCode === 100001) {
          service.logout();
          window.location.href = '/';
        } else {
          this.setState({audit: data.audit, table: true, page: page, total: data.total});
        }
      }
    );
  }

  changePage(p) {
    var page = this.state.page + p;

    this.getData(page);
  }

  render() {
    const { page, rowsPerPage, audit, table, total } = this.state;
    const pages = Math.ceil(total / rowsPerPage);

    const pinfo = <PaginationItem>
      <PaginationLink tag="button" style={{border: 0, color: 'black', margin: '0px 10px'}}>{(page-1) * rowsPerPage} - {page * rowsPerPage > total ? total : page * rowsPerPage} of {total}</PaginationLink>
    </PaginationItem>

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
            { table ? 
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Audit trail</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Korisnik</th>
                        <th>Datum i vrijeme</th>
                        <th>Akcija</th>
                        <th>Poruka</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        audit.map((audit, index) => {
                          var dt = new Date(audit.log_start);
                          var d = service.nulling(dt.getDate());
                          var m = service.nulling(dt.getMonth() + 1);
                          var y = dt.getFullYear();
                          var h = service.nulling(dt.getHours());
                          var i = service.nulling(dt.getMinutes());
                          var s = service.nulling(dt.getSeconds());

                          var dtf = d + "." + m + "." + y + " " + h + ":" + i + ":" + s;

                          return (
                            <tr key={index}>
                              <td>{audit.user_name}</td>
                              <td>{dtf}</td>
                              <td>{audit.program_title}</td>
                              <td>{audit.output_param}</td>
                            </tr>    
                          )
                        })
                      }
                    </tbody>
                  </Table>
                  <nav style={{float: 'right'}}>
                    <Pagination>
                      <PaginationItem onClick={() => page > 1 ? this.changePage(-1) : null }>
                        <PaginationLink disabled={page > 1 ? '': true}>
                          Prethodna
                        </PaginationLink>
                      </PaginationItem>
                      {pinfo}
                      <PaginationItem onClick={() => page < pages ? this.changePage(1) : null}>
                        <PaginationLink next tag="button" disabled={page < pages ? '': true}>
                          Sljedeća
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem></PaginationItem>
                    </Pagination>
                  </nav>
                </CardBody>
              </Card>
              :
                <Loader
                  type="Rings"
                  className="loader"
                  height={100}
                  width={100}

                />
              }
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Audit;
