import React from "react";
import ReactExport from "react-export-excel";
import { Button } from "reactstrap";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class Download extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            report: [],
            reports: this.props.reports,
            date_from: this.props.date_from,
            date_to: this.props.date_to,
        };
    }

    componentDidUpdate() {
        if (this.state.reports !== this.props.reports || this.state.date_from !== this.props.date_from || this.state.date_to !== this.props.date_to) {
            this.setState({report: this.getReport(this.props.reports, this.props.date_from, this.props.date_to), reports: this.props.reports, date_from: this.props.date_from, date_to: this.props.date_to});
        }
    }

    getReport(r, d_from, d_to) {
        var d_from_f = d_from.getDate() + "." + (d_from.getMonth() + 1) + "." + d_from.getFullYear();
        var d_to_f = d_to.getDate() + "." + (d_to.getMonth() + 1) + "." + d_to.getFullYear();

        var title_date = d_from_f;
        if (d_from_f !== d_to_f) title_date += " - " + d_to_f;

        var report = [{"name": "Datum", "total": title_date}, { "name": "", "total": "" }, { "name": "Lokacija", "total": "Broj izdanih obroka" }, { "name": "", "total": "" }, {"name": "Sve lokacije", "total": r.totals.total}, { "name": "", "total": "" }];

        r.locations.map((location) => {
            report.push({"name": location.name, "total": location.total});

            return true;
        });

        return report;
    }

    render() {
        const DownloadButton = <Button
            className="btn-round"
            color="alert"
            type="button"
        >
            Izvoz u Excel
        </Button>

        return (
            <ExcelFile element={DownloadButton}>
                <ExcelSheet data={this.state.report} name="Dnevno izvješće">
                    <ExcelColumn label="" value=""/>
                    <ExcelColumn label="" value="name"/>
                    <ExcelColumn label="" value="total"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}