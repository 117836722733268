/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Button,
} from "reactstrap";

import ReactTooltip from "react-tooltip";
import Loader from 'react-loader-spinner';

import { states } from 'variables/states';
import { service } from "services";
let states_array = [];

class Users extends React.Component {
  constructor(props) {
    super(props);

    this.getData = this.getData.bind(this);

    this.state = {
        users: [],
        showForm: false,
        table: false,
    };
  }

  componentDidMount() {
    states.map((state, index) => {
      states_array[state.id] = state.name;

      return true;
    });

    this.getData();
  }

  componentDidUpdate() {
    if (this.props.reload === true) {
      this.getData();
    }
  }

  getData() {
    this.props.reloaded();

    var u = 'users';
    const requestOptions = {
      method: 'GET',
      headers: { ...service.getSignedHeaders('GET', u) },
      body: null,
    };

    fetch(process.env.REACT_APP_API + u, requestOptions)
    .then(response => response.json())
    .then(
      data => {
        if (data.errorCode === 100001) {
          service.logout();
          window.location.href = '/';
        } else {
          this.setState({users: data.users, table: true });
        }
      }
    );
  }

  render() {
    return (
      <>
      { this.state.table ? 
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Lista korisnika</CardTitle>
                  <Button
                    className="btn-round"
                    color="alert"
                    type="button"
                    onClick={() => this.props.editHandler(0)}
                  >
                    Dodavanje novog korisnika
                  </Button>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Prezime i ime</th>
                        <th>Organizacija</th>
                        <th>Lokacija</th>
                        <th>Uloga</th>
                        <th className="text-center actionsColumn">Akcije</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.users.map((user, index) => {
                          return (
                            <tr key={index}>
                              <td>{user.last_name + " " + user.name}</td>
                              <td>{user.user_organization}</td>
                              <td>{user.location_name}</td>
                              <td>{user.role_name}</td>
                              <td className="text-center">
                                <i className="far fa-edit text-gray-dark actions" onClick={() => this.props.editHandler(user.id)} data-tip data-for={"edit" + index} />
                                <ReactTooltip id={"edit" + index} place="top" effect="solid">Izmjena korisnika { user.name + " " + user.last_name }</ReactTooltip>
                                <i className="far fa-trash-alt text-gray-dark actions" onClick={() => this.props.deleteHandler(user.id, 'Brisanje korisnika ' + user.first_name + " " + user.last_name)} data-tip data-for={"delete" + index} />
                                <ReactTooltip id={"delete" + index} place="top" effect="solid">Brisanje korisnika {user.name + " " + user.last_name}</ReactTooltip>
                              </td>
                            </tr>    
                          )
                        })
                      }
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
        :
          <Loader
            type="Rings"
            className="loader"
            height={100}
            width={100}

          />
        }
      </>
    );
  }
}

export default Users;
